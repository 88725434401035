import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { UsersFunctions } from "./index";
import AsyncSelector from "../../../components/AsyncSelector/AsyncSelector";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText="Identyfikator"
              error={errors.identifier}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.identifier,
                name: "identifier",
                onChange: handleChange,
                autocomplete: "off",
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Email"
              error={errors.email}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.email,
                name: "email",
                onChange: handleChange,
                type: "email",
              }}
              autoComplete="off"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Imię"
              error={errors.first_name}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.first_name,
                name: "first_name",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Nazwisko"
              error={errors.last_name}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.last_name,
                name: "last_name",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>
          {!values.edit_password && <GridItem xs={12} >
            <Button rounded size="sm" color="primary" onClick={() => setFieldValue("edit_password", true)}>Zmień hasło</Button>
          </GridItem> }
          {values.edit_password && <>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Hasło"
              error={errors.password}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.password,
                name: "password",
                onChange: handleChange,
                type: "password",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Powtórz hasło"
              error={errors.password_confirmation}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.password_confirmation,
                name: "password_confirmation",
                onChange: handleChange,
                type: "password",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>
          </> }
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Data Urodzenia"
              error={errors.birth_date}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "RRRR-MM-DD",
                value: values.birth_date,
                name: "birth_date",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Miasto"
              error={errors.city}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.city,
                name: "city",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Telefon"
              error={errors.phone}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.phone,
                name: "phone",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
              autoComplete="off"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.confirmed_phone}
                  onClick={() =>
                    setFieldValue("confirmed_phone", !values.confirmed_phone)
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Telefon potwierdzony"
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Płeć
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.gender}
                onChange={handleChange}
                inputProps={{
                  name: "gender",
                  autocomplete: "off",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="female"
                >
                  Kobieta
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="male"
                >
                  Mężczyzna
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={6}>
            <GridItem xs={12} md={6}>
              <AsyncSelector
                  apiMethod={Api.users.admin.index}
                  apiShowMethod={Api.users.admin.show}
                  labelKey="label"
                  label="Konto rodzica"
                  name="parent_id"
                  defaultValue={values.parent_id}
                  value={
                      values.parent && {
                        id: values.parent.id,
                        label: values.parent.label,
                      }
                  }
                  onChange={(value, data) => {
                    setFieldValue("parent", data);
                    setFieldValue("parent_id", value);
                  }}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem xs={3} style={{textAlign: 'right'}}>
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
